import T from "../../../components/forms/TextField.module.css";

import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    type ElementProps,
} from "@stripe/react-stripe-js";
import type {
    CreateTokenCardData,
    Stripe,
    StripeCardNumberElement,
    StripeElementChangeEvent,
    StripeElementClasses,
    StripeElementStyle,
} from "@stripe/stripe-js";
import type { StripeCardConfig } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { useImperativeHandle, useState, type ReactNode } from "react";
import { Input, Label, TextField } from "react-aria-components";
import { useForm, type UseFormReturn } from "react-hook-form";
import invariant from "tiny-invariant";
import { useAriaField } from "../../../components/forms/ariaFieldRegister.ts";
import { requiredMessage } from "../../../components/forms/requiredMessage.ts";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { useApiClient } from "../../api/apiClient.store.ts";
import { getResponseData, type ApiClient } from "../../api/apiClient.ts";
import { useThemeVar } from "../../theme/useTheme.ts";
import type { AddMethodParams, AddMethodProps } from "../choose_method/AddMethodParams.ts";
import { addCardRequest, cardVerifyRequest } from "../choose_method/addMethodUtils.ts";
import { FormBox } from "../choose_method/FormBox.tsx";
import { CardNameField, ErrorText } from "../payment_forms/components.tsx";
import { cardCvcLabel, cardExpiryLabel, cardNumberLabel } from "../payment_forms/labels.tsx";
import { throwStripeError } from "./throwStripeError.ts";

interface ElementError {
    type: "validation_error";
    code: string;
    message: string;
}

interface ElementState {
    error: ElementError | undefined;
    focused: boolean;
}

/**
 * Track the error and focused states of a Stripe element.
 *
 * @returns [state, props] where `state` is the {@link ElementState} and `props` are the props to pass to the Stripe element.
 */
function useElementState() {
    const [state, setState] = useState<ElementState>({ error: undefined, focused: false });

    return [
        state,
        {
            onChange: (e) => setState((s) => ({ ...s, error: e.error })),
            onFocus: () => setState((s) => ({ ...s, focused: true })),
            onBlur: () => setState((s) => ({ ...s, focused: false })),
        } satisfies ElementProps & { onChange: (e: StripeElementChangeEvent) => void },
    ] as const;
}

/** Display an error for a Stripe element */
function ElementError({ state }: { state: ElementState }) {
    return state.error && <ErrorText text={state.error.message} />;
}

/** Wrap a raw Stripe element in a container with common Input attributes & styling */
function FakeInput({ children, state }: { children: React.ReactNode; state: ElementState }) {
    return (
        <div
            className={T.Input}
            data-invalid={state.error ? 1 : undefined}
            data-focused={state.focused ? 1 : undefined}
        >
            {children}
        </div>
    );
}

function FakeTextField({ children }: { children: ReactNode }) {
    return <div className={T.TextField}>{children}</div>;
}

export interface StripeAddFormProps extends AddMethodProps {
    stripe: Promise<Stripe>;
    config: StripeCardConfig;
}

export function StripeAddForm(props: StripeAddFormProps) {
    return (
        // NB: Stripe requires an extra wrapper component with <Elements> so the child components can access the elements via `useElements()`
        <Elements stripe={props.stripe} options={{ mode: "setup", currency: "usd" }}>
            <StripeAddFormImpl {...props} />
        </Elements>
    );
}

/** Convert theme variables into Stripe element style options */
function useInputStyles(): { style?: StripeElementStyle; classes?: StripeElementClasses } {
    const color = useThemeVar("input-text-color");
    const fontFamily = useThemeVar("body-font-family");
    const fontSize = useThemeVar("input-text-size");
    const invalidColor = useThemeVar("color-error");

    return {
        // NB: Have to use raw styles since eg. the CVC element is in an iframe
        style: {
            base: { color, fontFamily, fontSize },
            invalid: { color: invalidColor },
        },
    };
}

type Status = { status: string; error?: never } | { status?: never; error: unknown };

async function doAdd(
    { account: { id: billing_account_id }, returnUrl }: AddMethodParams,
    apiClient: ApiClient,
    stripePromise: Promise<Stripe>,
    numberElement: StripeCardNumberElement,
    tokenData: CreateTokenCardData,
    progress: (message: string) => void,
) {
    const stripe = await stripePromise;

    progress("Creating token");
    const createTokenResult = await stripe.createToken(numberElement, tokenData);
    throwStripeError(createTokenResult.error);
    const { token } = createTokenResult;

    progress("Saving payment method");
    const addCardResult = await addCardRequest(apiClient, { billing_account_id, token: token.id });

    try {
        progress("Preparing verification");
        const prehookResult = getResponseData(
            await apiClient.POST("/payment/card/prehook", { ...jsonEncodedBody, body: { billing_account_id } }),
        );

        progress("Verifying payment method");
        const confirmResult = await stripe.confirmCardSetup(prehookResult.client_secret, {
            payment_method: addCardResult.identifier,
        });
        throwStripeError(confirmResult.error);

        progress("Saving verification");
        const _verifyResult = await cardVerifyRequest(apiClient, addCardResult.id, returnUrl);

        progress("Done");
    } catch (e) {
        console.error("Failed to verify card: %o", e);
        // TODO: Delete card if verification fails
        throw e;
    }
}

interface StripeAddInputs {
    name: string;
    postalCode: string;
}

function StripeAddFormImpl({ stripe, config, actionsRef }: StripeAddFormProps) {
    const showPostalCode = !!config.show_postalcode_input;
    // TODO: Only autofocus when adding in modal (otherwise will take focus away from other fields in eg. Billing Create)
    // const autoFocus = false;

    //#region Hooks
    const elements = useElements();
    const apiClient = useApiClient();

    const form = useForm<StripeAddInputs>();

    const [status, setStatus] = useState<Status | undefined>(undefined);

    useImperativeHandle(actionsRef, () => ({
        async validate() {
            return await form.trigger(undefined, { shouldFocus: true });
        },
        async addPaymentMethod(params) {
            console.debug("Submitting Stripe form");

            try {
                invariant(elements, "Elements must be loaded");

                const numberElement = notNull(elements.getElement("cardNumber"), "cardNumber");
                const cardData: CreateTokenCardData = {
                    name: form.getValues("name"),
                    address_zip: showPostalCode ? form.getValues("postalCode") : undefined,
                };
                await doAdd(params, apiClient, stripe, numberElement, cardData, (m) => setStatus({ status: m }));
            } catch (e) {
                console.error("Failed to add card: %o", e);
                setStatus({ error: e });
                throw e;
            }
        },
    }));

    const [cardNumberState, cardNumberProps] = useElementState();
    const [cardExpiryState, cardExpiryProps] = useElementState();
    const [cardCvcState, cardCvcProps] = useElementState();

    const inputOptions = useInputStyles();
    //#endregion

    return (
        <FormBox className="flex flex-col gap-2">
            {status?.error ? (
                <div className="text-error">{String(status.error)}</div>
            ) : status?.status ? (
                <div className="text-muted">{status.status}</div>
            ) : null}

            <CardNameField form={form} name="name" />

            <FakeTextField>
                <Label className={T.Label}>{cardNumberLabel.label}</Label>
                <FakeInput state={cardNumberState}>
                    <CardNumberElement options={inputOptions} {...cardNumberProps} />
                </FakeInput>
                <ElementError state={cardNumberState} />
            </FakeTextField>

            <div className="flex gap-2">
                <FakeTextField>
                    <Label className={T.Label}>{cardExpiryLabel.label}</Label>
                    <FakeInput state={cardExpiryState}>
                        <CardExpiryElement options={inputOptions} {...cardExpiryProps} />
                    </FakeInput>
                    <ElementError state={cardExpiryState} />
                </FakeTextField>

                <FakeTextField>
                    <Label className={T.Label}>{cardCvcLabel.label}</Label>
                    <FakeInput state={cardCvcState}>
                        <CardCvcElement options={inputOptions} {...cardCvcProps} />
                    </FakeInput>
                    <ElementError state={cardCvcState} />
                </FakeTextField>

                {showPostalCode && <PostalCodeField form={form} />}
            </div>
        </FormBox>
    );
}

function PostalCodeField({ form }: { form: UseFormReturn<StripeAddInputs> }) {
    const { props, ref } = useAriaField("postalCode", form, { required: requiredMessage });

    return (
        <TextField className={T.TextField} {...props}>
            <Label className={T.Label}>Postal Code</Label>
            <Input className={T.Input} ref={ref} />
            <ErrorText text={props.errorMessage} />
        </TextField>
    );
}

export default StripeAddForm;
